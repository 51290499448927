import Btn from '@/components/dataDisplay/Btn';
import Markdown from '@/components/dataDisplay/Markdown';
import { AMPLITUDE_ACTIONS } from '@/modules/core/amplitude/events';
import { LogEvent } from '@/modules/core/amplitude/types/Amplitude';
import useCustomer from '@/modules/core/data/hooks/useCustomer';
import { Customer } from '@/modules/core/data/types/Customer';
import I18nLink from '@/modules/core/i18n/components/I18nLink';
import { Amplitude } from '@amplitude/react-amplitude';
import {
  css,
  useTheme,
} from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createLogger } from '@/modules/core/logging/logger';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Row,
} from 'reactstrap';
import AnimatedBook from '@/modules/home/components/AnimatedBook';
import AnimatedDesktop from '@/modules/home/components/AnimatedDesktop';
import AnimatedPhone from '@/modules/home/components/AnimatedPhone';

const fileLabel = 'components/pageLayouts/DefaultLayout';
const logger = createLogger({
  fileLabel,
});

type Props = {};

/**
 * Dynamically displays the modules, based on whether they've been activated.
 * Doesn't do anything complicated, basically displays a few animations and links towards the other modules.
 */
const Home: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { t } = useTranslation();
  const { primaryColor, onBackgroundColor, backgroundColor } = useTheme();
  const customer: Customer = useCustomer();
  const { modChatbot, modSLG, modSLS } = customer;
  const isChatbotModuleEnabled = modChatbot?.isEnabled;
  const isSLSModuleEnabled = modSLS?.isEnabled;
  const isSLGModuleEnabled = modSLG?.isEnabled;

  // Calculates the number of enabled modules to display, it'll be used to resolve the columns size
  const modulesCount: number = 1 + (isSLSModuleEnabled ? 1 : 0) + (isSLGModuleEnabled ? 1 : 0);
  const columnMdSize: number = 12 / modulesCount;

  return (
    <Amplitude>
      {
        ({ logEvent }: { logEvent: LogEvent }): JSX.Element => {
          return (
            <Row
              css={css`
                margin-right: 0;
                margin-bottom: 30px;
                outline: 0;

                a {
                  text-decoration: none !important;
                }

                .module-card {
                  text-align: center;
                  color: ${onBackgroundColor};
                  background-color: ${backgroundColor};
                  margin: 40px;
                  padding: 30px;
                  border-radius: 30px;
                  transition: box-shadow 0.5s ease-in-out;
                  cursor: pointer;

                  &:hover {
                    box-shadow: 4px 7px 30px -12px ${primaryColor};
                    transition: box-shadow 0.5s ease-in-out;

                    .btn {
                      box-shadow: 4px 7px 30px -12px ${primaryColor};
                      transition: box-shadow 0.5s ease-in-out;
                    }
                  }

                  p {
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 16.5px;
                  }

                  svg {
                    max-height: 380px;
                  }
                }
              `}
            >
              {
                <Col
                  md={columnMdSize}
                  xs={12}
                  className={'animated fadeIn'}
                >
                  <I18nLink
                    // If the chatbot is disabled, then we will redirect directly to the studentSolutions module
                    href={isChatbotModuleEnabled ? `/chatbot` : '/solutions'}
                  >
                    <div
                      id={'chatbot-card'}
                      className={'module-card'}
                    >
                      <AnimatedPhone />
                      <h2>
                        {modChatbot?.homeTitle}
                      </h2>
                      <h3>
                        {modChatbot?.homeSubtitle}
                      </h3>
                      <Markdown
                        text={modChatbot?.homeDescription}
                      />
                      <Btn>
                        <FontAwesomeIcon icon="arrow-right" />
                        {modChatbot?.homeButtonLabel}
                      </Btn>
                    </div>
                  </I18nLink>
                </Col>
              }

              {
                isSLSModuleEnabled && (
                  <Col
                    className={'animated fadeIn'}
                    md={columnMdSize}
                    xs={12}
                  >
                    <I18nLink
                      href={`/simulator`}
                    >
                      <div
                        id={'simulator-card'}
                        className={'module-card'}
                      >
                        <AnimatedDesktop />
                        <h2>
                          {modSLS?.homeTitle}
                        </h2>
                        <h3>
                          {modSLS?.homeSubtitle}
                        </h3>
                        <Markdown
                          text={modSLS?.homeDescription}
                        />
                        <Btn>
                          <FontAwesomeIcon icon="arrow-right" />
                          {modSLS?.homeButtonLabel}
                        </Btn>
                      </div>
                    </I18nLink>
                  </Col>
                )
              }

              {
                isSLGModuleEnabled && (
                  <Col
                    className={'animated fadeIn'}
                    md={columnMdSize}
                    xs={12}
                  >
                    <a
                      href={t('homePage.modules.SLG.documentLink', 'https://storage.googleapis.com/unly-student-loan-guide/Guide%20pre%CC%82t%20e%CC%81tudiant.pdf')}
                      target={'_blank'}
                      rel="noopener noreferrer"
                      onClick={(): void => {
                        logEvent('SLG-ebook-opened', {
                          action: AMPLITUDE_ACTIONS.CLICK,
                        });
                      }}
                    >
                      <div
                        id={'student-loan-guide-card'}
                        className={'module-card'}
                      >
                        <AnimatedBook />
                        <h2>
                          {modSLG?.homeTitle}
                        </h2>
                        <h3>
                          {modSLG?.homeSubtitle}
                        </h3>
                        <Markdown
                          text={modSLG?.homeDescription}
                        />
                        <Btn>
                          <FontAwesomeIcon icon="arrow-to-bottom" />
                          {modSLG?.homeButtonLabel}
                        </Btn>
                      </div>
                    </a>
                  </Col>
                )
              }
            </Row>
          );
        }
      }
    </Amplitude>
  );
};

export default Home;
